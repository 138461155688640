const projectsData = [
  {
    id: 1,
    name: "Markdown Editor",
    descShort:
      "Fullstack application, where users can sign-in, create, edit & export markdown files. ",
    descLong:
      "Markdown Editor allows users to create an account who then can create edit and save, their markdown files. It also has an export feature so users can export their markdown as ether a markdown file or pdf. This project also features a theme toggle, so users can choose their preference of light mode or dark mode. All files are saved and backedup to a mongoDB database.",
    thumbNail: "markdown-editor-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "Javascript",
      "Next.JS",
      "Next-Auth",
      "MongoDB",
      "MongooseJS",
      "Puppeteer",
      "TailwindCSS",
      "Next-Themes",
    ],
    repoUrl: "https://github.com/David-Henery4/Markdown-Editor",
    liveUrl: "https://markdown-editor-md.vercel.app",
  },
  {
    id: 2,
    name: "Produck-Feedback",
    descShort:
      "A product feedback application, where users can create and interact with feedback for a project they may be working on.",
    descLong:
      "Produck feedback is a product feedback app, where users can create, view and edit different posts about feedback they have for a specific product. This app also has many different features like a sort and filter feature, dark mode toggle and the ability to add comments and replies to different posts and many more.",
    thumbNail: "produck-feedback-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "Next.JS",
      "Next-Auth",
      "MongoDB",
      "MongooseJS",
      "Redux-Toolkit",
      "TailwindCSS",
      "Framer Motion",
    ],
    repoUrl: "https://github.com/David-Henery4/Produck-Feedback-App",
    liveUrl: "https://produck-feedback-app.vercel.app",
  },
  {
    id: 3,
    name: "Elite Aquatics",
    descShort: "A website I built and designed for a local aquatics company.",
    descLong:
      "This is a website I built and designed for a local aquatics company, it has many different features needed for a website like this. It has a testimonial section with live reviews coming from google, A gallery showcasing their work and a form that customers can use to get in touch and enquire about their services.",
    thumbNail: "elite-aquatics-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "Next.JS",
      "Axios",
      "Leaflet.JS",
      "TailwindCSS",
      "Figma",
    ],
    repoUrl: "Repo Private",
    liveUrl: "https://eliteaquaticsuk.uk",
  },
  {
    id: 4,
    name: "Dev-Jobs",
    descShort: "A website displaying different development jobs available.",
    descLong:
      "A website listing different dev-jobs that are available. This site has features like being able to filter jobs by title, location, and whether a job is for a full-time position, click a job and see that jobs individual page so that they can read more information and apply for the job and a dark mode toggle which takes in to account the users OS system preference.",
    thumbNail: "devjobs-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "Vue.JS",
      "TailwindCSS",
      "Router.vue.js ",
      "Pinia.vue.js",
    ],
    repoUrl: "https://github.com/David-Henery4/dev-jobs",
    liveUrl: "https://dev-jobs-4u.netlify.app",
  },
  {
    id: 5,
    name: "Audiophile",
    descShort:
      "Audiophile is a multi page Ecommerce site for different audio equipment.",
    descLong:
      "Audiophile is a music equipment ecommerce site. Users can browse different products and categries. It also has cart full cart functionality and checkout validation for when users want to make a purchase.",
    thumbNail: "audiophile-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "React-Router-6",
      "Redux-Toolkit",
      "Redux-Persist",
    ],
    repoUrl: "https://github.com/David-Henery4/Audiophile-Ecommerce-site",
    liveUrl: "https://audiophile-ecommerce-store.netlify.app",
  },
  {
    id: 6,
    name: "Entertainment",
    descShort:
      "This multi-page entertainment web app, where users can bookmark and search for different shows and movies.",
    descLong:
      "Entertainment is a multi-page entertainment app. Users can search for different content and also view filtered pages that show all the T.V shows available or all the movies that are available. It also has a dedicated page to display all the different movies & T.V shows the users has bookmarked.",
    thumbNail: "entertainment-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "React-Router-6",
      "Redux-Toolkit",
      "TailwindCSS",
      "Axios",
      "Json-Server",
      "Redux-persist",
    ],
    repoUrl: "https://github.com/David-Henery4/Entertainment-V1",
    liveUrl: "https://thats-entertainment-v1.netlify.app",
  },
  {
    id: 7,
    name: "Kanban Task Manager",
    descShort:
      "Kanban Tasks manager is a fully-functional task management app with a light/dark mode toggle. It also has a drag and drop feature.",
    descLong:
      "Kanban Tasks is a task management app that allows users to control different tasks, update their status and create different task boards. Users can also change their tasks status by dragging them into different columns.",
    thumbNail: "kanban-task-manager-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "Redux-Persist",
      "Redux-Toolkit",
    ],
    repoUrl: "https://github.com/David-Henery4/Kanban-Task-Manager",
    liveUrl: "https://kanban-taskmanager.netlify.app",
  },
  {
    id: 8,
    name: "QK Invoices",
    descShort:
      "QK Invoices is an full-stack invoice app, which allows users to read, create, update & delete invoices.",
    descLong:
      "QK Invoices is an full-stack invoice app. The app has authorization & authentication and allows users to create and sign into an account. It also allows users to create, read, update & delete their own invoices. The app has several different features, it has a filter option to filter invoices based on their status, form validation when entering a invoice and it also has a dark/light mode so the user can choose their preference.",
    thumbNail: "invoice-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS",
      "Javascript",
      "Git",
      "Vite",
      "React",
      "React-Router",
      "Redux-Toolkit",
      "TailwindCSS",
      "Node.js",
      "MongoDB",
      "Express.js",
      "Axios",
      "Json-Web-Token",
    ],
    repoUrl: "https://github.com/David-Henery4/Invoice-app-Frontend",
    liveUrl: "https://qk-invoices.netlify.app/login",
  },
  {
    id: 9,
    name: "Designo",
    descShort:
      "Designo is a fictional language multi-page site for a design compnay that offers custom designs and digital branding solutions.",
    descLong:
      "Designo is a fictional multi-page site for a design compnay. Which offers users different types of design services. The site has a few different features, such as a contact form with form validation, as well as interactive maps in the locations page showing where the companys head offices are in the world.",
    thumbNail: "designo-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "Git",
      "React",
      "TailwindCSS",
      "Vite",
      "React-Router",
      "Leaflet.js",
    ],
    repoUrl: "https://github.com/David-Henery4/Designo-Website",
    liveUrl: "https://designo-customs.netlify.app",
  },
  {
    id: 10,
    name: "Where in the world?",
    descShort:
      "Allows users to search for countries and see information about them.",
    descLong:
      "This application lets users search for different countries around the world and find out details about them. There is also an option to allow users to filter countries by different regions. It also has a dark/light mode toggle for the users preference",
    thumbNail: "where-in-the-world-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "React-Router-6",
      "Redux-Toolkit",
      "Axios",
    ],
    repoUrl: "https://github.com/David-Henery4/Where-in-the-world",
    liveUrl: "https://where-abouts-in-the-world.netlify.app",
  },
  {
    id: 11,
    name: "Interactive Comments Section",
    descShort:
      "This is a fully interactive comments section, where users can create, edit, like, reply and delete comments",
    descLong:
      "Interactive Comments Section is fully interactive comments section. where users can add, edit and delete their own messages. Users can also reply and like other messages. Messages also display a dynamic timer to keep track of when a message was posted.",
    thumbNail: "interactive-comments-section-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "Redux-Persist",
      "Redux-Toolkit",
    ],
    repoUrl: "https://github.com/David-Henery4/Interactive-Comments-Section",
    liveUrl: "https://comments-interactive-section.netlify.app",
  },
  {
    id: 12,
    name: "Ip address tracker",
    descShort:
      "Ip Address Tracker let's users see their own Ip address information aswell as search for details about other Ip addresses.",
    descLong:
      "Ip address tracker allows users to search for details about a specific Ip address, while also showing details about the users Ip address. It also has an interactive map which shows the location of the users initial ip address location and any seearched for ip addresses.",
    thumbNail: "ip-address-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "Redux-Toolkit",
      "Axios",
      "Git",
      "Leaflet",
    ],
    repoUrl: "https://github.com/David-Henery4/IP-Address-Tracker",
    liveUrl: "https://ip-address-tracker-mentor.netlify.app",
  },
  {
    id: 13,
    name: "Pay API",
    descShort:
      "This is a 4 page website, for a company that sells and distributes different APIs.",
    descLong:
      "This is a 4 page website, for a company called Pay API, that sells and distributes different APIs. It has a shared email sign-up thoughout most pages which has validation. It also has a full contact form page which also provides a validation check.",
    thumbNail: "pay-api-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "React-Router-6",
      "TailwindCSS",
    ],
    repoUrl: "https://github.com/David-Henery4/pay-api-website",
    liveUrl: "https://apy-api-site.netlify.app",
  },
  {
    id: 14,
    name: "Bookmarks",
    descShort:
      "A landing page for a company that sell software that helps users bookmark and orginise websites.",
    descLong:
      "Bookmarks is a landing page for a company that sells sfotware that helps to orginse & bookmark your website. It has multiple sections, including a tabbed features section that allows you to switch between the tabs for different features. It also has an accordion section for FAQs and also some light email validation on a contact us section.",
    thumbNail: "bookmarks-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: ["HTML", "CSS/SCSS", " Javascript", "TailwindCSS"],
    repoUrl: "https://github.com/David-Henery4/Bookmark-Landing",
    liveUrl: "https://bookmarks-home-landing.netlify.app",
  },
  {
    id: 15,
    name: "myTeam",
    descShort:
      "This is a dashboard style site for people to check for basic information about a football team.",
    descLong:
      "myTeam lets users search for stats & information about their favourite football teams. Users can get different statistics about their teams current season and can get information about their teams history and more! myTeam also has a light mode & darkmode for the users preference.",
    thumbNail: "myTeam-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "Chart.js",
      "Parcel",
      "Git",
    ],
    repoUrl: "https://github.com/David-Henery4/myTeam-Football-Dashboard",
    liveUrl: "https://myteam-football-dashboard.netlify.app",
  },
  {
    id: 16,
    name: "Pomodoro",
    descShort:
      "A pomodoro timer for users to keep track of time using the pomodoro technique.",
    descLong:
      "This app lets users to use a timer when using the pomodoro technique. It also has different options to allow the user to select a timer for their break time, one for a short break and one for a long break. The app also has a settings menu so users can select custom durations for their timers, as well as being able to choose different font types & a different colour scheme.",
    thumbNail: "pomodoro-thumb.png",
    deviceMockupsSml: "device-mockups-small.png",
    deviceMockupsLge: "device-mockups-large.png",
    technologiesUsed: [
      "HTML",
      "CSS/SCSS",
      "Javascript",
      "React",
      "TailwindCSS",
    ],
    repoUrl: "https://github.com/David-Henery4/Pomodoro-App",
    liveUrl: "https://pomodoro-countdown-app.netlify.app",
  },
];

export default projectsData;